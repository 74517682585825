body {
    font-family: Boopee;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }
  
  .hero,
  .tokenomics,
  .roadmap,
  .socials {
    width: 100%;
    box-sizing: border-box;
    padding: 60px 20px;
  }
  
  .hero {
    text-align: center;
    background-color: #f5f5f5;
    background-image: url('/public/assets/Hero.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .hero h2 {
    font-size: 2rem;
  }
  
  .tokenomics h2,
  .roadmap h2,
  .socials h2 {
    font-size: 4rem;
    margin-bottom: 20px;
  }
  
  .tokenomics p,
  .roadmap p,
  .socials p {
    font-size: 1rem;
    color: #666;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
  }
  
  .social-icon img {
    width: 40px;
    height: 40px;
    margin: 0 10px;
  }
  
  @media (max-width: 768px) {
    .hero {
      height: auto;
      padding: 40px 20px;
    }
  
    .hero h2 {
      font-size: 1.5rem;
    }
  
    .tokenomics h2,
    .roadmap h2,
    .socials h2 {
      font-size: 1.5rem;
    }
  
    .tokenomics p,
    .roadmap p,
    .socials p {
      font-size: 0.9rem;
    }
  
    .social-icon img {
      width: 30px;
      height: 30px;
    }
  }
  