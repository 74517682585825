/* Hero.css */

.hero {
    text-align: center;
    margin-top: 80px;
    padding: 60px 20px;
    background-color: #f5f5f5;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .hero h2 {
    font-size: 2rem;
  }
  