/* Socials.css */

.socials {
    padding: 60px 20px;
    background-color: #111111;
    text-align: center;
  }
  
  .socials h1 {
    font-size: 4rem;
    margin-bottom: 1px;
    color: #FFFFFF;
  }
  
  .socials p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .social-icons {
    padding: 20px 10px;
    display: flex;
    justify-content: center;
  }
  
  .social-icon img {
    width: 40px;
    height: 40px;
    margin: 0 10px;
  }
  