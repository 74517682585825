/* Tokenomics.css */

.tokenomics {
    padding: 60px 20px;
    background-color: #111111;
    text-align: center;
  }
  
  .tokenomics h1 {
    font-size: 8rem;
    margin-bottom: 20px;
    color: #FFFFFF;
  }
  
  .tokenomics h2 {
    font-size: 4rem;
    color: #FFFFFF;
  }
  