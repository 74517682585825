/* Drem.css */

.drem {
    padding: 1px 20px;
    background-color: #111111;
    text-align: center;
  }
  
  .drem h1 {
    font-size: 8rem;
    margin-bottom: 10px;
    color: #FFFFFF;
  }
  
  .drem h2 {
    font-size: 5rem;
    color: #FFFFFF;
  }
  
  .drem h3 {
    font-size: 4rem;
    color: #FFFFFF;
  }
  