/* Navbar.css */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 40px;
    color: rgb(255, 255, 255);
    position: relative;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box; /* Ensure padding is included in width */
  }
  
  .navbar-logo img {
    height: 70px;
  }
  
  .navbar-right {
    display: flex;
    align-items: center;
  }
  
  .navbar-links {
    display: flex;
    margin-right: 20px;
  }
  
  .navbar-links a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    margin: 0 15px;
    font-size: xx-large;
  }
  
  .navbar-links a:hover {
    text-decoration: underline;
  }
  
  .navbar-button {
    display: flex;
    align-items: center;
  }
  
  .button.chart {
    text-decoration: none;
    padding: 10px 20px;
    background-color: black;
    color: white;
    border-radius: 30%;
    border: 2px solid black;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }
  
  .button.chart:hover {
    transform: scale(1.1);
    background-color: #1a1a1a;
  }
  
  /* Hamburger menu styles */
  .navbar-hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .navbar-hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 4px 0;
  }
  
  /* Media query for small screens */
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      padding: 10px 20px;
    }
  
    .navbar-right {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      display: none; /* Hide menu by default */
    }
  
    .navbar-right.open {
      display: flex; /* Show menu when open */
      align-items: center; /* Center items horizontally */
    }
  
    .navbar-links {
      flex-direction: column;
      width: 100%;
      margin: 0;
      align-items: center; /* Center links horizontally */
    }
  
    .navbar-links a {
      margin: 10px 0;
      font-size: large;
    }
  
    .navbar-button {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  
    .navbar-hamburger {
      display: flex; /* Show hamburger menu on mobile */
    }
  
    .button.chart {
      width: auto; /* Ensure button width doesn't stretch */
      padding: 10px 20px;
      font-size: large; /* Adjust font size for mobile */
    }
  }
  