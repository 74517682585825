/* Section4.css */

.section4 {
  padding: 20px;
  background-color: #111111;
}

.slick-list {
  perspective: 1000px;
}

.slick-slide {
  transition: transform 0.5s ease;
  transform-style: preserve-3d;
}

.slick-slide img {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
}

.slick-center img {
  transform: scale(1.1) translateZ(100px);
}

.slick-slide:not(.slick-center) img {
  transform: scale(0.9) translateZ(-100px) rotateY(20deg);
}

.slick-prev:before, .slick-next:before {
  color: black;
}
