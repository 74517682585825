/* Roadmap.css */

.roadmap {
    padding: 60px 20px;
    background-color: #111111;
    text-align: center;
  }
  
  .roadmap h1 {
    font-size: 8rem;
    margin-bottom: 20px;
    color: #FFFFFF;
  }
  
  .roadmap p {
    font-size: 1rem;
    color: #FFFFFF;
  }
  